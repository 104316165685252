import React from 'react'
import AccountLayout from '../../components/Account/AccountLayout'
import Layout from "../../components/Layout"
import Checkbox from "../../components/Checkbox"
import Input from "../../components/Input"
import SvgIcon from "../../components/SvgCurve"
import { Link } from 'gatsby'
import Select from "../../components/Select"

export default function AddAddress() {
    const options = [
        { value: 'Nicaragua', label: 'Nicaragua' },
        { value: 'Costa Rica', label: 'Costa Rica' },
        { value: 'El Salvador', label: 'El Salvador' }
    ]
    return (
        <Layout>
            <AccountLayout>
                <div className="AddAddress">
                    <div className="AddAddress-header">
                        <span className="AddAddress-title">
                            <SvgIcon className="AddAddress-icon" icon="addresses" />
                            Mis direcciones
                        </span>
                        <Link className="AddAddress-arrow">
                            <SvgIcon className="AddAddress-arrowBack" icon="arrowBack" />
                            Volver
                        </Link>
                    </div>
                    <form action="">
                        <Input label="Nombre del destinatario" />
                        <Input label="Dirección principal" />
                        <Select label="País" options={options} />
                        <div className="AddAddress-row">
                            <Input label="Localidad" className="AddAddress-input" />
                            <Input label="Código Postal" />
                        </div>
                        <Input label="Teléfono" />
                        <Input label="Como quieres guardar esta dirección (casa, trabajo, etc.)" />
                        <Checkbox className="AddAddress-checkbox" classNameLabel="AddAddress-checkLabel" text="Convierte esta dirección como principal" />
                        <Checkbox className="AddAddress-checkbox" classNameLabel="AddAddress-checkLabel" text="Esta es mi dirección de facturación" />
                        <button className="AddAddress-btn">Guardar</button>
                    </form>
                </div>
            </AccountLayout>
        </Layout>
    )
}
